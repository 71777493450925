<template>
  <section class="Overreal wrapper" id="article">
  <h1>
    <span>4</span>
    {{ $t("home.overReal.title") }}
  </h1>
  <article>
    <p>
      {{ $t("home.overReal.description0") }}
    </p>
    <p>
      {{ $t("home.overReal.description1") }}
    </p>
    <p>
      {{ $t("home.overReal.description2") }}
    </p>
  </article>
  </section>
</template>

<script>
export default {
  name: "Overreal.vue",
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {
  },
}
</script>

<style lang="scss">
/*  Overreal.vue  */
.Overreal.vue {
  width: 100%;
  height: auto;
}
</style>